<template>
  <section>
    <div v-if="get_popular_products && get_popular_products.length" class="item-slider-buttons">
      <h3 class="title title__normal">
        {{ translations.random_items }}
      </h3>
      <product_slider
        :products="get_popular_products"
        :load_right_away="true"
      />
    </div>
    <collection_banners v-if="show_collection_banners"/>
    <story_banner />
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { home } from "../../../../data/other_constants"
import { CLIENT_STORE } from "../../constants/other"
import { ARE_ANY_PRODUCTS_BEING_FETCHED } from "../../stores/Client/constants"

const collection_banners = () => import("../utils/home/collection_banners")
const story_banner = () => import("../utils/home/story_banner")
const product_slider = () => import("../../../Shared/components/utils/product_slider")

export default {
  components: {
    collection_banners,
    story_banner,
    product_slider
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "banners",
      "translations",
      "products",
      "popular_products"
    ]),
    ...mapGetters(CLIENT_STORE, {
      still_fetching_products: ARE_ANY_PRODUCTS_BEING_FETCHED
    }),
    get_popular_products() {
      const found_products = this.products.filter(({ id }) => this.popular_products.includes(id))

      return [
        ...found_products,
        ...(this.still_fetching_products ? Array.from(new Array(this.popular_products.length - found_products.length)).map(_ => ({})) : [])
      ]
    },
    show_collection_banners() {
      /**
       * Check if there are ant product category banners and
       * that the first element isnt an empty string.
       * Empty string because firebase DB cant hold an empty array,
       * so we need to provide it with at least one element
       */
      return (this.banners.collection_banners || []).length
    }
  },
}
</script>
